.gist[id^='gist'] {
  background: var(--color-gray-lightest);
  padding: rem(8px);
  margin-bottom: 2rem;

  .gist-data,
  .gist-file {
    border: none;
    border-radius: 0;
  }

  .gist-data {
    background: transparent;
  }

  .gist-file {
    margin-bottom: 0;
  }

  .gist-meta {
    display: none;
  }

  .highlight {
    background: transparent;
  }
}
