.pagination {
  display: flex;
  margin-bottom: 0;

  @include list-unstyled();

  &__link,
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: map-get($font-sizes, 'sm');
    font-weight: map-get($font-weight, 'bolder');

    min-width: 2rem;
    min-height: 2rem;
  }
}
