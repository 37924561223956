.popular-article {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__title {
    line-height: 1.25rem;

    margin-bottom: rem(8px);
  }

  &__title-link {
    @include multiple-line-underline();

    font-size: map-get($font-sizes, 'default');
    font-weight: $font-weight-bolder;
    color: $blog-card-article-headline-color;

    &:hover {
      color: inherit;
    }

    &:after {
      content: initial;
    }
  }

  &__author {
    font-size: map-get($font-sizes, 'sm');
    color: $blog-card-article-author-color;
  }

  &__timestamp {
    font-size: map-get($font-sizes, 'sm');
    color: $blog-card-article-timestamp-color;
  }
}
