table {
  width: 100%;
  margin: 2rem 0;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1.0rem;

  thead, tbody {
    border: 1px solid map-get($color-gray, 'primary');
  }

  th {
    @include text-truncate();

    padding: $spacer;
    font-weight: 600;
    text-align: left;
    background: map-get($color-gray, 'light');
  }

  td {
    border-bottom: 1px solid map-get($color-gray, 'primary');
    padding: $spacer/2 $spacer;
  }

  tr,
  td,
  th {
    vertical-align: middle;
  }
}
