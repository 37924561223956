.layout-blog {
  scroll-behavior: smooth;
}

.layout-blog body {
  background-color: var(--color-brand-valhalla);
}

.layout-blog .app-header,
.layout-blog .app-content,
.layout-blog .app-footer {
  @include make-content-container();
}

.layout-blog .app-header {
  position: fixed;
  z-index: map-get($zIndex, 'highest');
  top: 0;
}

.layout-blog .app-content {
  min-height: calc(100vh - #{$blog-header-height} - #{$blog-footer-height});
  margin-top: $blog-header-height;

  background: hsl(0, 0%, 100%);

  @include media-breakpoint-up('md') {
    min-height: calc(100vh - #{$blog-header-height-md} - #{$blog-footer-height-md});
    margin-top: $blog-header-height-md;
  }
}
