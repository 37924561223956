.app-header {
  color: hsl(0, 0%, 100%);
}

.app-header__logo {
  width: rem(99px);

  padding: 0;
  margin: 0;

  @include media-breakpoint-up('md') {
    width: rem(132px);

    padding-top: rem(4px);
  }
}

.app-header__menu {
  width: 100%;

  margin: rem(16px 0 24px);
  padding-top: rem(26px);

  @include media-breakpoint-up('xl') {
    width: auto;

    margin: (0 24px 0 0);
    padding-top: 0;
  }

  &-item {
    display: flex;

    @include media-breakpoint-up('xl') {
      display: inline-block;
    }
  }

  &-item:not(:last-child) {
    margin-bottom: rem(24px);

    @include media-breakpoint-up('xl') {
      margin-right: rem(24px);
      margin-bottom: 0;
    }
  }

  &-link {
    display: block;

    color: hsl(0, 0%, 100%);
    font-size: map-get($font-sizes, 'default');

    &:hover {
      color: inherit;
    }
  }

  &:before {
    position: absolute;
    background-color: hsl(0, 0%, 100%);

    width: 100%;
    height: 1px;
    bottom: rem(-24px);

    opacity: $blog-header-divider-opacity;
    content: '';

    @include media-breakpoint-up('xl') {
      width: 1px;
      height: rem(24px);
      right: rem(-24px);
      bottom: 0;
    }
  }
}

.app-header__btn-home {
  font-weight: map-get($font-weight, 'bolder');
  margin-top: rem(24px);
  padding: 0;

  @include media-breakpoint-up('xl') {
    margin-top: 0;
    margin-left: rem(24px);
  }

  // .btn is required to override the default position/display properties
  &,
  &:hover {
    color: hsl(0, 0%, 100%);
  }

  &:hover {
    cursor: pointer;
  }

  > span {
    @include animated-underline();
  }

  > span,
  .icon {
    display: inline-block;
  }

  .icon {
    color: var(--color-brand-red-ribbon);

    margin-left: rem(10px);
  }
}
