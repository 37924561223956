body.article .blog-article {
  display: grid;
  grid-template-areas:
    'cover'
    'toc'
    'content'
    'recruitment'
    'recommendations'
    'subscription'
    'sidebar';

  @include media-breakpoint-up('lg') {
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
  }

  @include media-breakpoint-up('xl') {
    grid-template-columns: minmax(auto, map-get($grid-breakpoints, 'lg')) $blog-article-side-panel-width;
    grid-column-gap: $blog-article-column-gap-width;
    grid-template-areas:
      'cover cover'
      'content sidebar'
      'recruitment sidebar'
      'recommendations sidebar'
      'subscription sidebar';

    justify-content: center;
  }

  @include capitalized-title();
}

body.article .blog-article__cover {
  grid-area: cover;

  margin-top: 2rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up('md') {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

body.article .blog-article__content {
  grid-area: content;

  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: relative;

    height: rem(4px);
    top: rem(2px);

    background: {
      image: url(map-get($sprites, 'shape-11'));
      repeat: no-repeat;
      position: center;
      size: rem(36px);
    }
  }
}

body.article .blog-article__recruitment {
  grid-area: recruitment;

  border-bottom: solid 1px var(--color-brand-athens-gray);
  margin-bottom: 1rem;
}

body.article .blog-article__recommendations {
  grid-area: recommendations;

  margin-bottom: 2rem;
  border-bottom: solid 1px var(--color-brand-athens-gray);
}

body.article .blog-article__toc {
  grid-area: toc;

  margin-bottom: 2rem;

  @include media-breakpoint-up('xl') {
    display: none;
  }
}

body.article .blog-article__sidebar {
  grid-area: sidebar;

  margin-top: 1rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up('xl') {
    margin-top: 0;
  }

  &-container {
    &--sticky {
      @include media-breakpoint-up('xl') {
        position: -webkit-sticky;
        position: sticky;
        top: calc(#{$blog-header-height-md} + 5rem);
      }
    }
  }

  &-toc {
    display: block;

    @include media-breakpoint-down('lg') {
      display: none;
    }
  }

  &-social-and-sharing {
    margin-top: 1rem;
  }
}

body.article .blog-article__subscription {
  grid-area: subscription;
  margin-bottom: 1rem;

  @include media-breakpoint-up('xl') {
    margin-bottom: rem(144px);
  }
}
