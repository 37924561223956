.list-toc {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;

  list-style: none;
  list-style-position: inside;
  font-size: map-get($font-sizes, 'default');
  font-weight: $font-weight-bolder;

  a {
    color: $blog-card-article-headline-color;

    &:after {
      display: none;
    }

    &:hover {
      color: map-get($color-brand, 'red-ribbon');
    }
  }

  @include media-breakpoint-up('xl') {
    padding-top: 0;
    font-size: map-get($font-sizes, 'md');
  }

  > li:first-child:before {
    @include media-breakpoint-up('xl') {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 1px;
      height: 100%;
      background: map-get($color-gray, 'primary');
    }
  }

  &__item {
    @include media-breakpoint-down('lg') {
      padding-top: rem(8px);
      padding-bottom: rem(8px);
    }

    &::before {
      display: none;
    }
  }

  // .list-toc__h3 is auto generated by jekyll-toc as a sub-heading
  &__h3,
  &__sub-list {
    @include media-breakpoint-down('lg') {
      display: none;
    }

    a {
      font-weight: $font-weight-lighter;
    } 
  }

  &__sub-list {
    padding-left: rem(32px);
  }
}
