.article-recruitment {
  background-color: var(--color-brand-red-ribbon);
  padding: 2rem;
  margin-bottom: 2rem;

  background: {
    image: url(map-get($sprites, 'shape-10'));
    repeat: no-repeat;
    position: right bottom;
    size: rem(72px);
  }

  @include media-breakpoint-up('lg') {
    background: {
      image: url(map-get($sprites, 'shape-9'));
      size: rem(216px);
    }
  }

  &__content {
    color: hsl(0, 0%, 100%);
  }

  &__link,
  &__link:hover {
    @include multiple-line-underline();

    color: #fff;

    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);
  }

  &__button {
    padding: rem(13px 16px 11px);
  }
}
