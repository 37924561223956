.syntax-highlight {
  background: var(--color-gray-lightest);
  padding: rem(8px 32px);
  margin-bottom: 2rem;

  width: calc(100vw - #{$grid-gutter-width});

  @include media-breakpoint-up('lg') {
    width: calc(100vw - (#{$grid-gutter-width * 2} + #{$grid-gutter-width}));
  }

  @include media-breakpoint-up('xl') {
    width: calc(100vw - (#{$grid-gutter-width * 2} + #{$grid-gutter-width} + #{$blog-article-side-panel-width} + #{$blog-article-column-gap-width}));
    max-width: map-get($grid-breakpoints, 'lg');
  }
}
