.card-article-cover {
  display: grid;
  grid-template-areas:
    'image-container'
    'headline'
    'description'
    'footer';

  @include media-breakpoint-up('md') {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(32px);
    grid-template-areas:
      'image-container headline'
      'image-container description'
      'image-container footer'
      'image-container .';
  }
}

.card-article-cover__feature-type,
.card-article-cover__title,
.card-article-cover__description,
.card-article-cover__footer {
  @include media-breakpoint-up('xl') {
    margin-right: rem(32px);
    margin-left: rem(32px);
  }
}

.card-article-cover__footer {
  grid-area: footer;

  display: grid;
  grid-template-columns: rem(40px) auto;
  grid-template-areas:
    'avatar author'
    'avatar timestamp'
    'tag tag';

  @include media-breakpoint-up('md') {
    grid-template-columns: rem(40px) repeat(2, 1fr);
    grid-template-areas:
      'avatar author tag'
      'avatar timestamp tag'
      '. . tag';
  }
}

.card-article-cover__image-container {
  grid-area: image-container;
  margin-bottom: rem(32px);

  @include media-breakpoint-up('md') {
    margin-bottom: 0;
  }
}

.card-article-cover__image {
  width: 100%;
  height: auto;
}

.card-article-cover__title {
  grid-area: headline;
  margin-bottom: rem(16px);

  font-size: rem(36px);
  line-height: 1.25;
  text-indent: initial;
}

.card-article-cover__title-link {
  @include multiple-line-underline();

  color: map-get($color-gray, 'dark');

  &:hover {
    color: inherit;
  }

  &:after {
    content: initial;
  }
}

.card-article-cover__description {
  grid-area: description;
  margin-bottom: rem(24px);

  font-size: map-get($font-sizes, 'xl');
  color: $blog-card-article-description-color;
}

.card-article-cover__avatar {
  grid-area: avatar;

  &-image {
    width: 100%;
    height: auto;

    border-radius: rem(32px);
  }
}

.card-article-cover__author {
  grid-area: author;
  margin-left: rem(12px);

  font-size: map-get($font-sizes, 'sm');
  color: $blog-card-article-author-color;
}

.card-article-cover__timestamp {
  grid-area: timestamp;
  margin-left: rem(12px);

  font-size: map-get($font-sizes, 'sm');
  color: $blog-card-article-timestamp-color;
}

.card-article-cover__tag {
  grid-area: tag;
  margin-top: rem(16px);

  text-align: left;

  @include media-breakpoint-up('md') {
    margin-top: 0;

    text-align: end;
    align-self: flex-end;
  }
}

.card-article-cover__tag-item {
  display: inline-block;

  font-size: map-get($font-sizes, 'sm');
  font-weight: $font-weight-bolder;
  color: $blog-card-article-tag-item-color;
  text-transform: uppercase;

  &:not(:last-child):before {
    content: ',';
    float: right;
  }
}
