.share-platform {
  display: inline-table;

  &__item {
    display: inherit;
  }

  &__item:not(:last-child) {
    margin-right: 1rem;
  }

  &__link {
    @include transition(background-color $base-animation-duration);

    display: flex;
    align-items: center;
    justify-content: center;

    color: inherit;
    background-color: transparent;

    width: 2rem;
    height: 2rem;

    &:hover,
    &:active {
      color: hsl(0, 0%, 100%);
      background-color: map-get($color-gray, 'dark');
    }
  }
}
