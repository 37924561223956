.article-recommendations {
  padding: 1rem 0 2rem;

  &__title {
    font-size: map-get($font-sizes, 'default');
    margin-bottom: rem(10px);
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: rem(4px);
  }

  &__link {
    @include multiple-line-underline();

    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);
  }
}
